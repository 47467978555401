.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
:root {
    --ionicon-stroke-width: 32px;
    --ion-color-primary: #0fa;
    --ion-color-secondary: #fa0;
    --ion-color-tertiary: #f4a942;
    --ion-color-success: #10dc60;
    --ion-color-warning: #ffce00;
    --ion-color-danger: #f14141;
    --ion-color-light: #f4f5f8;
    --ion-color-medium: #989aa2;
    --ion-color-dark: #222428;
}
